import 'scss/pages/404.scss';
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/SEO'
import {Link} from 'gatsby';
import Image from "components/atoms/Image/Image";

const NotFoundPage = () => (
  <Layout header={false} contactForm={false} footer={false} className="not-found-page">
    <Seo title="Not found" />
      <Link to="/">
        <Image name="404-page/content-mobile" className="cover-image cover-image--mobile" />
        <Image name="404-page/content-tablet" className="cover-image cover-image--tablet" />
        <Image name="404-page/content-desktop" className="cover-image cover-image--desktop" />
      </Link>
  </Layout>
)

export default NotFoundPage;
